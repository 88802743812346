<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.svg')" width="180" height="88" />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-0">
        <v-col cols="12" class="text-center">
          <h3 :class="$style.title">{{ titleEn }}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 mb-3 rounded-xl"
            rounded
            :class="$style.form"
          >
            <v-row dense>
              <v-col cols="12">
                <v-list flat class="py-0">
                  <v-list-item-group>
                    <template v-for="(item, index) in items">
                      <v-list-item
                        :key="index"
                        :to="`${item.name}`"
                        class="pa-0"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.label"
                          ></v-list-item-title>
                          <v-list-item-title
                            v-text="item.labelEn"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider
                        v-if="index !== items.length - 1"
                        :key="`divide-${index}`"
                        :class="$style.separator"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-center"
            :class="$style['logout-btn']"
            color="#FD6955"
            text
            @click="onClickLogoutBtn"
          >
            サインアウト (Sign out)
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            class="text-center"
            :class="$style['logout-btn']"
            color="#FD6955"
            text
            @click="goHome"
          >
            JaM id サイトに戻る <br />
            (Back to JaM id web site)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import utility from "@/utility";

export default {
  data() {
    return {
      resourceName: "mypage",
      title: "マイページ",
      titleEn: "My Page",
      items: [
        {
          label: "アカウント情報",
          labelEn: "Account Details",
          name: "account",
          icon: "mdi-account"
        }
        // {
        //   label: "お問い合わせ",
        //   labelEn: "Inquiry",
        //   name: "inquiry",
        //   icon: "mdi-email"
        // }
      ]
    };
  },
  computed: {
    entity() {
      return this.$store.getters[`accounts/entity/entity`];
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    onClickLogoutBtn() {
      utility
        .$confirm(
          "サインアウトしますか？ Do you want to sign out?",
          "確認 (Confirmation)",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        )
        .then(() => {
          const { accountInfo } = this.entity;
          this.$store.dispatch("accounts/entity/signOut");
        })
        .catch(() => {});
    },
    goHome() {
      window.location.href = "https://jam-id.jp";
    }
  },
  created() {
    this.$store.commit(`inquiry/entity/initialize`);
    this.$store.commit(`accounts/entity/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
    this.$store.dispatch(`accounts/entity/getAccountInfo`);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.separator {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.logout-btn {
  font-size: 13px !important;
  font-family: $body-font-family;
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}

.v-list {
  .v-list-item {
    .v-list-item__title {
      color: #718490;
      font-size: 1.3rem;
    }

    .v-list-item__subtitle {
      font-size: 1.2rem;
      color: #718490;
    }

    .v-list-item__icon {
      .v-icon {
        color: #718490;
      }
    }
  }
}
</style>
